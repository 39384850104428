body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  * {
    /* for firefox */
    scrollbar-width: thin;
    scrollbar-color: #cad5e1;
  }

  *::-webkit-scrollbar {
    width: 5px;
    height: 0px;
    /* background-color: #cad5e1; */
    background-color: #185195;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 50px;
    -webkit-box-shadow: inset 0 0 4px #ddd;
    box-shadow: inset 0 0 4px #ddd;
    background-color: #cad5e1;
    box-shadow: none;
  }

  *::-webkit-scrollbar-track,
  *::-webkit-scrollbar {
    background-color: inherit;
    box-shadow: none;
    border: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.time-format {
  border-color: #e3e3e3;
  box-shadow: none;
  font-size: 0.875rem;
  height: 44px;
  border: none;
  width: 10rem;
  margin-left: 5px;
  border-radius: 5px;
}

.active-table {
  background-color: #5e87b9 !important;
}

.active-table td {
  color: #ffffff !important;
  font-weight: bolder !important;
}

.dark-row {
  background-color: #f7f7f8 !important;
}

.active-table.dark-row {
  background-color: #5e87b9 !important;
}

.active-table.dark-row td {
  color: #ffffff !important;
}

.active-table.dark-row td .form-select__option {
  color: #000 !important;
}

.html-fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 1px;
  border-style: groove;
  border-color: #ced4da;
  border-image: initial;
}

.html-legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  float: none !important;
  width: fit-content;
  font-size: 1rem !important;
}
